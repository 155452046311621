import { App, defineAsyncComponent } from "vue";

export function install(app: App) {
  app.component(
    "RightToolbar",
    defineAsyncComponent(() => import("./RightToolbar/index.vue"))
  );
  app.component(
    "Pagination",
    defineAsyncComponent(() => import("./Pagination/index.vue"))
  );
  app.component(
    "ImageUpload",
    defineAsyncComponent(() => import("./ImageUpload/index.vue"))
  );
  app.component(
    "DictTag",
    defineAsyncComponent(() => import("./DictTag/index.vue"))
  );
}
