// 临时存储 避免重复请求
import { Local, Session } from "@/utils/storage";
import { getSystemInfoConfig } from "@/api/system/config";

export const globalConfigInfoKey = "globalConfigInfo";
/**
 * 获取系统配置
 * @returns {Promise<void>}
 */
export const loadSystemConfig = async () => {
  let globalConfigInfo = Session.get(globalConfigInfoKey);
  if (!globalConfigInfo) {
    let res = await getSystemInfoConfig();
    Session.set(globalConfigInfoKey, res.data);
    Local.set(globalConfigInfoKey, res.data);
  }
};

export const getSystemConfig = () => {
  return Local.get(globalConfigInfoKey);
};
